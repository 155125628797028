<template >
    <div class="dropdown" :onclick="toggle">
        <slot name="selecionado"></slot> 
        <ul>
            <slot name="itens"></slot>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "Dropdown",
        data() {
            return  {
                aberto: false
            }
        },
        methods: {
            toggle() {
                return this.aberto = !this.aberto
            }
        }
    }
</script>

<style scoped>
    
    .dropdown {
        color: #fff;
        cursor: pointer;
        padding: 4px;
        font-family: 'Raleway', sans-serif !important;
        font-weight: 700;
        box-sizing: border-box;
        font-style: italic;
        text-transform: uppercase;
    }

    .dropdown :slotted(.tituloDropdown) {
        font-family: 'Raleway', sans-serif !important;
        font-weight: 600;
        font-size: 11px;
        margin-bottom: 16px; 
        text-transform:unset;
        padding: 4px;
    }
    .dropdown :slotted(.seta)  {
        font-size: 16px;
        margin-left: 8px;
    }

    .dropdown:hover :slotted(.seta) {
        transform: rotate(180deg);
    }
    
    .dropdown ul {
        display: none;
    }
    
    .dropdown:hover ul {
        background-color: white;
        color: black;
        display: flex;
        flex-direction: column;
        font-style: italic;
        position: absolute;
    }

    .dropdown :slotted(.lista) {
        font-size: 14px;
        padding: 7px 7px;
        text-align: start;
    }
    .dropdown :slotted(.lista:last-of-type)  {
        padding-bottom: 10px;
    }

    .dropdown :slotted(.lista:hover),
    .dropdown :slotted(.lista:hover) a
    {
        background-color: black;
        color: white;
        cursor: pointer;
    }

    .dropdown :slotted(.lista) a {
        color: black;
        text-decoration: none;
    }

    @media(max-width: 375px) {
        .dropdown :slotted(.lista) {
            font-size: 14px;
        }
    }

</style>